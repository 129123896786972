<template>
    <BasicInformationCard title="Project foto's" :editRouterLink="{name: this.routeNames.PROJECT_DETAIL_EDIT_IMAGES, params: {clientId: clientId, projectId: projectId}}"
                          :promises.sync="promises">
        <template v-slot:content>
            
            <div v-if="!images || images.length <= 0" class="d-flex flex-column flex-grow-1 w-100">
                <v-icon class="align-self-center flex-grow-1" size="110px">mdi-image-off-outline</v-icon>
                <div class="align-self-center">Er zijn geen afbeeldingen beschikbaar</div>
            </div>
            <v-container v-else-if="$vuetify.breakpoint.mdAndUp">
                <v-overlay :value="overlayImage" :z-index="500" >
                    <v-btn color="$fossil-gray"
                           absolute
                           dark
                           top
                           right
                           class="mt-9"
                           small
                           depressed
                           fab
                           @click="closeOverlayImage">
                      <v-icon>{{ icon }}</v-icon>
                    </v-btn>
                    <v-img :src="overlayImage + '?' + getSASKey" max-width="100vw" max-height="100vh" contain/>
                </v-overlay>
                <v-slide-group show-arrows="always" ref="imagesSlideGroup" @click:next="loadNextImagesPage">
                    <v-slide-item v-for="image in images" :key="image" class="ma-2">
                        <v-img :src="image + '?' + getSASKey" contain height="25vh" max-width="25vw" class="align-self-start clickable"  @click="toggleImageOverlay(image)">
                            <template v-slot:placeholder>
                                <v-sheet
                                class="rounded"
                                color="cultured-gray"
                                height="100%">
                                    <v-skeleton-loader
                                    transition="scale-transition"
                                    height="100%"
                                    class="mx-auto"
                                    type="image"/>
                                </v-sheet>
                            </template>
                        </v-img>
                    </v-slide-item>
                </v-slide-group>
            </v-container>
            <v-carousel v-else height="250px">
                <v-carousel-item
                v-for="(image,i) in images"
                :key="i"
                :src="image + '?' + getSASKey"
                max-height="200px" contain>
                    <template v-slot:placeholder>
                        <v-sheet
                        class="rounded"
                        color="cultured-gray"
                        height="100%">
                            <v-skeleton-loader
                            transition="scale-transition"
                            height="100%"
                            class="mx-auto"
                            type="image"/>
                        </v-sheet>
                    </template>
                </v-carousel-item>
            </v-carousel>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue';
import routeNames from "@/router/RouteNames";

export default {
    name: "ProjectImagesComponent",
    data() {
        return {
            model: 0,
            overlayImage: null,
            images: [],
            pageCount: null,
            routeNames,
            promises: [
                this.$store.dispatch("fileExportModule/fetchSASKey")
                    .then(result => this.sasKey = result),
                //loads the first 6 images (2x3)
                //To make sure the content of the slidegroup overflows, this causes the slide group to enable a button to slide right
                //This button also loads more images
                this.loadNextImagesPage().then(() => this.loadNextImagesPage()),
            ],
            sasKey: null,
            currentPage: 0,
          icon: "mdi-close"
        }
    },
    props: {
        clientId: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
    },
    computed: {
      getSASKey() {
        return this.$store.getters["fileExportModule/getSASKey"] || this.sasKey
      },
    },
  mounted() {
      document.addEventListener('keyup', (evt) =>{
        if (evt.keyCode === 27){
          this.closeOverlayImage()
        }
    })
  },
  methods: {
        toggleImageOverlay(image) {
            if (this.overlayImage) {
            this.closeOverlayImage()
            } else {
            this.overlayImage = image
            }
        },
        loadNextImagesPage() {
            if (!this.pageCount || this.currentPage < this.pageCount) {
                this.currentPage++
                return this.$store.dispatch("projectsModule/fetchImages", {clientId: this.clientId, projectId: this.projectId, page: this.currentPage, count: 3})
                .then(result => {
                    this.images.push(...result.dtoList)
                    this.pageCount = result.pageCount
                })
            }
        },
        closeOverlayImage(){
              this.overlayImage = null
        }
    },
}
</script>
<style lang="scss" scoped>
// remove the darkner background effect of vuetify
  ::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__image {
    background: none
  }
</style>